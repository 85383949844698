import type { InjectionKey } from 'vue'

export const content = {
  url: {
    gcs_path: 'https://storage.googleapis.com/vket2022-uc',
    gcs_path_2023summer:
      'https://storage.googleapis.com/event-vket-uc/2023summer',
    cdn_path_2024summer: 'https://uc-vket-event.vketcdn.com/2024summer',
    r2_path_2023winter: 'https://uc-vket-event.vketcdn.com/2023winter',
    cdn_frontend: 'https://vket-event.vketcdn.com/2024Winter/',
    hikky: 'https://www.hikky.co.jp/',
    store: 'https://store.vket.com/',
    cloud: 'https://cloud.vket.com/',
    myvket: 'https://vket.com',
    vket2024w: 'https://event.vket.com/2024Winter/',
    vket2024s: 'https://event.vket.com/2024Summer/',
    vket2023w: 'https://event.vket.com/2023Winter/',
    vket2023s: 'https://event.vket.com/2023Summer/',
    vket2022w: 'https://winter2022.vket.com',
    musv4: 'https://music4.vket.com',
    musv5: 'https://music5.vket.com',
    comv2: 'https://comic.vket.com',
    comv3: 'https://comic3.vket.com/',
    gamv: 'https://game.vket.com/v0/',
    creator: 'https://crew.vket.com/',
    vrchat: 'https://hello.vrchat.com/',
    login: 'TODO: 後で修正する',
    twitter: 'https://twitter.com/Virtual_Market_',
    twitter_exhibit: 'https://twitter.com/vket_exhibitor',
    twitteren: 'https://twitter.com/EnVket',
    youtube: 'https://www.youtube.com/c/VketChannel',
    facebook: 'https://www.facebook.com/Virtual.Market.VKET/',
    discord: 'https://discord.com/invite/GcAGynr',
    discord_en: 'https://discord.com/invite/TZKWaSwGFc',
    instagram: 'https://www.instagram.com/vket_official/',
    privacy: 'https://account.vket.com/terms#privacy',
    privacyen: 'https://account.vket.com/terms?locale=en#privacy',
    termsja: 'https://account.vket.com/terms#event',
    termsen: 'https://account.vket.com/terms?locale=en#event',
    support: 'https://account.vket.com/support',
    twitterofficial: 'https://twitter.com/',
    noteofficial: 'https://note.com',
  },
  style: {
    query: 769,
    isdesktop: false,
    zindex0: 0,
    zindex1: 1,
    zindex2: 2,
    zindex3: 3,
  },
  redirect: {
    exclude: [
      'https://v-market.work',
      'https://www.v-market.work',
      'https://vket6.v-market.work',
      'https://mall.v-market.work',
      'https://vketlink.com/',
      'https://store.vket.com',
      'https://www.store.vket.com',
    ],
  },
  images: {
    none: '/2024Summer/images/no-image.png',
    none1x1: '/2024Summer/images/no-image_1x1.jpg',
  },
} as const

export const ContentInjectionKey: InjectionKey<typeof content> =
  Symbol('ContentType')
